<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-start p-5 bg-login">
        <b-img src="@/assets/images/logo-white.svg"></b-img>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        v-if="!remember_me"
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-0">
            Multicedi
            <b class="text-primary">Gateway</b>
          </b-card-title>
          <b-card-text class="mb-2"
            >Utilizza le tue credenziali per accedere.</b-card-text
          >

          <small v-if="error" class="text-danger">{{ error }}</small>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="mario.rossi@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="null" v-b-modal.modal-recovery>
                    <small>Password smarrita?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                  >Ricordami</b-form-checkbox
                >
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
                >Accedi</b-button
              >
            </b-form>
          </validation-observer>

          <p class="text-center mt-5">Per assistenza:</p>
          <p class="text-primary text-center font-weight-bold mt-2">
            <feather-icon class="mr-1" icon="PhoneCallIcon" />0823 123 456 789
          </p>
        </b-col>
      </b-col>

      <!-- Login Remember -->

      <b-col
        v-else
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto text-center">
          <b-card-title class="mb-0 font-weight-bold" title-tag="h2">
            Bentornato

            <b class="text-primary">{{ user.user_name }}!</b>
          </b-card-title>

          <small v-if="login_error != ''" class="text-danger">{{
            login_error
          }}</small>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- submit buttons -->
              <b-button class="mb-2" variant="primary" @click="login"
                >Entra in Multicedi Gateway</b-button
              >
            </b-form>
          </validation-observer>

          <small class="text-center"
            >Non sei tu?
            <b-link :to="null" @click="deleteData">
              <small>Esci ed effettua l'accesso</small>
            </b-link></small
          >
        </b-col>
      </b-col>
    </b-row>

    <!-- Modal -->

    <b-modal
      id="modal-recovery"
      centered
      title="Password Smarrita?"
      cancel-title="Annulla"
      @ok.prevent="validationPasswordRecovery"
    >
      <validation-observer ref="recoveryMailValidation">
        <b-form-group label="Inserisci Indirizzo E-mail di recupero">
          <validation-provider
            #default="{ errors }"
            name="E-mail di recupero"
            rules="email|required"
          >
            <b-form-input v-model="recovery_email"> </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-recovery-success"
      centered
      title="Richiesta Completata"
      ok-only
      :ok-disabled="loading"
    >
      <p>
        Se l'indirizzo e-mail specificato dovesse corrispondere ad un account
        esistente, riceverai una mail
      </p>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { Requests } from "@/api/requests.js";
import { mapActions } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: null,
      error: null,
      status: "",
      password: "",
      userEmail: "",
      recovery_email: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      remember_me: null,
      required,
      email,

      //Helper

      loading: false,
    };
  },

  created() {
    localize("it");

    this.userEmail = localStorage.getItem("user_mail");
    this.password = localStorage.getItem("user_secret");
    this.remember_me = localStorage.getItem("remember_me");

    this.getLoggedUser();
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions({ setUser: "setUser" }),

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },

    getLoggedUser() {
      if (localStorage.getItem("remember_me")) {
        const user_data = JSON.parse(localStorage.getItem("user_data"));
        this.user = user_data;
      }
    },

    deleteData() {
      this.remember_me = null;
      this.userEmail = "";
      this.password = "";
      localStorage.removeItem("remember_me");
      localStorage.removeItem("user_mail");
      localStorage.removeItem("user_secret");
      localStorage.removeItem("user_data");
    },

    validationPasswordRecovery() {
      this.$refs.recoveryMailValidation.validate().then((success) => {
        if (success) {
          this.passwordRecovery();
          this.$bvModal.hide("modal-recovery");
          this.$bvModal.show("modal-recovery-success");
        }
      });
    },

    async passwordRecovery() {
      this.loading = true;

      let data = new FormData();

      data.append("user_email", this.recovery_email);

      try {
        await Requests.passwordRecovery(data);
        this.recovery_email = "";
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    async login() {
      let data = new FormData();

      data.append("email", this.userEmail);
      data.append("password", this.password);

      try {
        const response = await Requests.login(data);

        switch (response.login.success) {
          case "true": {
            localStorage.setItem("access_token", response.login.token);
            const user = await this.getUser();
            this.setUser(user);
            if (this.status) {
              localStorage.setItem("remember_me", "true");
              localStorage.setItem("user_mail", this.userEmail);
              localStorage.setItem("user_secret", this.password);
              localStorage.setItem("user_data", JSON.stringify(user));
            }
            this.$router.push("/dashboard");
            break;
          }

          case "false": {
            this.error = response.login.feedback;
            break;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getUser() {
      try {
        const response = await Requests.getUser();
        return response.me.payload;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.bg-login {
  background-image: url("../assets/images/login-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
